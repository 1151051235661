import React, { useState } from 'react';
import Billboard from './Billboard';
import RegForm from './RegForm';
import AllInOne from './AllInOne';
// import Social from './Social';
// import useInterval from '../../utils/UseInterval';
import Video from './Video';
import SayHello from './Video/SayHello';
// import CTA from './CTA';
// import ContactInfo from './AllInOne/ContactInfo';
// import Back from '../About/Resume/Back';
import Me from './Me';

const Main = ({ isVisible, isCurrentLayer, inTransition, onMouseClick, setShowLoading }) => {
	return (
		<div className="contact-page__main abcde">
			<div className="g-grid__item a">
				<AllInOne isVisible={isVisible} />
			</div>
			<div className="g-grid__item b">
				<Billboard isVisible={isVisible} />
			</div>
			<div className="g-grid__item c">
				<Me />
			</div>

			<div className="g-grid__item d">
				<Video isVisible={isVisible} />
			</div>
			<div className="g-grid__item e">
				<SayHello isVisible={isVisible} />
			</div>
			<div className="g-grid__item f" />
			<div className="g-grid__item g" />
		</div>
	);
};

export default Main;
