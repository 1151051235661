import React, { forwardRef, useEffect, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Sections from '../index';
import Introduction from './Introduction';
import { scrollSectionByUrlHash } from '../../pages/';

const Projects = forwardRef(({ isVisible, reverse, state, otherProjects }, ref) => {
	// const { projects } = state;
	const { allMarkdownRemark: { edges: projects } } = useStaticQuery(graphql`
		query ProjectsList {
			allMarkdownRemark(sort: { fields: frontmatter___order, order: ASC }) {
				edges {
					node {
						id
						frontmatter {
							slug
							project
							logo
							colors
							client
							buttonTitle
							banner
							technology
							thumbnail
							title
							year
							order
						}
					}
				}
			}
		}
	`);

	const sections = useMemo(
		() => {
			const CHUNKS = 7;
			const selectedProjects = otherProjects || projects; // override default projects
			return selectedProjects.reduce((prev, curr, index) => {
				const next = index % CHUNKS === 0;
				if (next) {
					prev.push([ curr ]);
				} else {
					prev[prev.length - 1].push(curr);
				}
				return prev;
			}, []);
		},
		[ projects ]
	);

	const id = 'projects-page';

	useEffect(() => {
		scrollSectionByUrlHash(id);
	}, []);
	console.log(sections);
	return (
		<div id={id}>
			{sections &&
				sections.map((projects, i) => (
					<div className="projects-page page" key={i} ref={ref}>
						<Sections start={0} loop={false} reverse={i % 2} isVisible={isVisible}>
							<Introduction projects={projects} />
						</Sections>
					</div>
				))}
		</div>
	);
});

Projects.displayName = 'Projects';

export default Projects;
