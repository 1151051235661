// import dat from 'dat.gui';
import { createContainer } from 'unstated-next';
import React, { useState, useMemo, useEffect } from 'react';

const GUI = () => {
	const [ factor, setFactor ] = useState(12);
	const [ width, setWidth ] = useState(48);
	const [ height, setHeight ] = useState(0.3);
	const [ x, setX ] = useState(-0.3);
	const [ y, setY ] = useState(-1.44);
	const [ z, setZ ] = useState(0.77);
	const [ xRotate, setXRotate ] = useState(0);
	const [ yRotate, setYRotate ] = useState(0);
	const [ zRotate, setZRotate ] = useState(0);
	const [ intensity, setIntensity ] = useState(40);
	const [ distance, setDistance ] = useState(50);
	const [ angle, setAngle ] = useState(Math.PI / 3);
	const [ penumbra, setPenumbra ] = useState(0.5);
	const [ decay, setDecay ] = useState(1.5);
	const [ dispScale, setDispScale ] = useState(0.1);
	const [ dispBias, setDispBias ] = useState(0);
	const [color, setcolor] = useState('0xffffff');
	const [linewidth, setlinewidth] = useState(2);
	const [scale, setscale] = useState(0.05);
	const [dashSize, setdashSize] = useState(3);
	const [gapSize, setgapSize] = useState(1);

	const [color1, setcolor1] = useState('#fff');
	const [color2, setcolor2] = useState('#ff006b');
	const [color3, setcolor3] = useState('#002f3d');
	
	// const gui = useMemo(() => new dat.GUI(), []);

	useEffect(() => {
		/*
		gui.add({ scale }, 'scale', 0, 0.1, 0.001).onChange((value) => setscale(value));
		gui.add({ x }, 'x', -20, 20, 0.01).onChange((value) => setX(value));
		gui.add({ y }, 'y', -20, 20, 0.01).onChange((value) => setY(value));
		gui.add({ z }, 'z', -20, 20, 0.01).onChange((value) => setZ(value));

		gui.add({ width }, 'width', 0, 100, 0.5).onChange((value) => setWidth(value));
		gui.addColor({ color1 }, 'color1').onChange((value) => setcolor1(value));
		gui.addColor({ color2 }, 'color2').onChange((value) => setcolor2(value));
		gui.addColor({ color3 }, 'color3').onChange((value) => setcolor3(value));
				
		gui.add({ factor }, 'factor', 0, 100, 0.2).onChange((value) => setFactor(value));

		gui.add({ xRotate }, 'xRotate', -Math.PI/2, Math.PI/2, 0.1).onChange((value) => setXRotate(value));
		gui.add({ yRotate }, 'yRotate', -Math.PI/2, Math.PI/2, 0.1).onChange((value) => setYRotate(value));
		gui.add({ zRotate }, 'zRotate', -Math.PI/2, Math.PI/2, 0.1).onChange((value) => setZRotate(value));
		
		
		gui.add({ linewidth }, 'linewidth', 0, 20, 0.1).onChange((value) => setlinewidth(value));
		gui.add({ dashSize }, 'dashSize', 0, 20, 0.1).onChange((value) => setdashSize(value));
		gui.add({ gapSize }, 'gapSize', 0, 20, 0.1).onChange((value) => setgapSize(value));
		
		
		gui.add({ dispScale }, 'dispScale', 0, 5, 0.1).onChange((value) => setDispScale(value));
		gui.add({ dispBias }, 'dispBias', 0, 5, 0.1).onChange((value) => setDispBias(value));
		gui.add({ factor }, 'factor', -100, 100, 0.1).onChange((value) => setFactor(value));
		
		gui.add({ height }, 'height', 0, 100).onChange((value) => setHeight(value));
		gui.add({ x }, 'x', -30, 30, 0.1).onChange((value) => setX(value));
		gui.add({ y }, 'y', -30, 30, 0.1).onChange((value) => setY(value));
		gui.add({ z }, 'z', -30, 30, 0.1).onChange((value) => setZ(value));
		gui.add({ xRotate }, 'xRotate', 0, 270).onChange((value) => setXRotate(value));
		gui.add({ yRotate }, 'yRotate', 0, 270).onChange((value) => setYRotate(value));
		gui.add({ zRotate }, 'zRotate', 0, 270).onChange((value) => setZRotate(value));
		gui.add({ intensity }, 'intensity', 0, 60).onChange((value) => setIntensity(value));
		gui.add({ distance }, 'distance', 50, 200).onChange((value) => setDistance(value));
		gui.add({ angle }, 'angle', 0, Math.PI / 2).onChange((value) => setAngle(value));
		gui.add({ penumbra }, 'penumbra', 0, 1).onChange((value) => setPenumbra(value));
		gui.add({ decay }, 'decay', 1, 2).onChange((value) => setDecay(value));
		*/
	}, []);

	return {
		// color1,
		// color2,
		// color3,
		// factor,
		// width,
		// height,
		x,
		y,
		z,
		// xRotate,
		// yRotate,
		// zRotate,
		// intensity,
		// distance,
		// angle,
		// penumbra,
		// decay,
		// dispScale,
		// dispBias,
		// linewidth,
		scale,
		// dashSize,
		// gapSize
	};
};

export default createContainer(GUI);
