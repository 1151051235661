import React, { useRef } from 'react';
import ScrollIcon from './scroll.inline.svg';
import ArrowIcon from './arrow.inline.svg';
import styles from './skills.scss';

const Skills = () => {
	const skills = useRef([
		"ReactJS",
		"NextJS",
		"Tailwindcss",
		"RadixUI",
		"Styled Component",
		"Firebase",
		"NodeJS",
		"Typescript",
		"Zustand",
		"Cyress",
		"Statsig",
		"Figma",
		"Photoshop",
		"Framer Motion",
		"CSS",
		"P5.js",
		"Twilio Segment Dataware house",
		"Customer.io",
		"Salesforce Flow"
	]);
	return (
		<div className="resume-skills-menu">
			<div className="scroll-indicator">
				<ArrowIcon />
			</div>
			<nav className="resume-menu">
				{skills.current.map((skill, index) => (
					<div key={index} className="resume-menu__item">
						<a className="resume-menu__item-inner">{skill}</a>
					</div>
				))}
			</nav>
		</div>
	);
};

export default Skills;
