import React, { useContext, useEffect, useState } from 'react';
import Loadable from '@loadable/component';
// import P5Wrapper from 'react-p5-wrapper';
import Flocking from './flocking/Flocking';
import { RegFormContext } from './index';
const LoadableP5 = Loadable(() => import('react-p5-wrapper'));

const Background = ({ isVisible }) => {
	const [ state, setState ] = useContext(RegFormContext);
	const [ mode, setMode ] = useState('normal');

	useEffect(
		() => {
			const { isSubmitted } = state;
			if (isSubmitted) setMode('submit');
		},
		[ state.isSubmitted ]
	);
	useEffect(
		() => {
			const { isFocus } = state;
			if (isFocus) setMode('focus' + Date.now());
		},
		[ state.isFocus ]
	);
	useEffect(
		() => {
			const { isHover } = state;
			if (isHover) setMode('hoverIn');
			else setMode('hoverOut');
		},
		[ state.isHover ]
	);
	useEffect(() => {
		setMode('normal');
	}, []);
	return <LoadableP5 sketch={Flocking} mode={mode} isVisible={isVisible} />;
	// <P5Wrapper sketch={Flocking} mode={mode} isVisible={isVisible} />;
};

export default Background;
