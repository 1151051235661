import React from 'react';
import Background from './Background';
// import Playground from './Playground';
import Hero from './Hero';
import Billboard from './Billboard';
// import DiscoverButton from './DiscoverButton';
// import ProjectTitle from './ProjectTitle';
// import Thumbnail from './Thumbnail';
import RabbitHole from './RabbitHole';
// import useInterval from '../../utils/UseInterval';
import random from 'lodash.random';

const convertHexToRGBA = (hexCode, opacity) => {
	let hex = hexCode.replace('#', '');

	if (hex.length === 3) {
		hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
	}

	const r = parseInt(hex.substring(0, 2), 16);
	const g = parseInt(hex.substring(2, 4), 16);
	const b = parseInt(hex.substring(4, 6), 16);

	return `rgba(${r},${g},${b},${opacity / 100})`;
};

export const gradientBackground = (c1, c2, c3) => {
	return `
            radial-gradient(${random(45, 125)}% ${random(70, 180)}% at ${random(0, 20)}% ${random(
		0,
		20
	)}%, ${c3} 0%, ${convertHexToRGBA(c3, Math.random() * 20)} 100%), 
            radial-gradient(${random(20, 90)}% ${random(80, 100)}% at ${random(30, 80)}% ${random(
		30,
		70
	)}%, ${c2} 0%,${convertHexToRGBA(c2, Math.random() * 20)} 100%),         
            linear-gradient(${random(0, 360)}deg, ${c1}, ${convertHexToRGBA(c1, Math.random() * 20)})
            `;
};

export const simpleGradientBackground = (c1, c2, c3) => {
	return `
		radial-gradient(top left, ellipse, ${c3} 0%, transparent), 
		linear-gradient(to bottom, ${c2}, ${c2} 60%, ${c1}, ${c3})
	`;
};

const Introduction = ({ isVisible, isCurrentLayer, inTransition, onMouseClick }) => {
	return (
		<div className="work-page__introduction abc">
			<div className="g-grid__item a">
				<Background />
				<Hero isVisible={isVisible} isCurrentLayer={isCurrentLayer} inTransition={inTransition} />
			</div>
			<div className="g-grid__item b">
				<Billboard />
			</div>
			<div className="g-grid__item c">
				<RabbitHole />
				{/* <Thumbnail current={count} clients={CLIENTS} /> */}
			</div>
			<div className="g-grid__item d">{/* <ProjectTitle current={count} clients={CLIENTS} /> */}</div>
			<div className="g-grid__item e">{/* <DiscoverButton current={count} clients={CLIENTS} /> */}</div>
			<div className="g-grid__item f" />
			<div className="g-grid__item g" />
		</div>
	);
};

export default Introduction;
