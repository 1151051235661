import React from 'react'
import video from './billboard-bg-video.mp4'

const Billboard = () => {
    return (
        <div className="billboard about-billboard">
            <video className="billboard__background video" autoPlay playsInline muted loop src={video} />
            <div className="billboard__text">
                <h5 className="billboard__subtitle"></h5>
                <h1 className="billboard__title">Skills</h1>
                <h3 className="billboard__caption">                    
                    What<br/>do<br/>I<br/>know
                </h3>
            </div>

        </div>
    )
}

export default Billboard
