import React from 'react';
import Loadable from '@loadable/component';
// import P5Wrapper from 'react-p5-wrapper';
import Title from './Title';
import Ribbon from './Ribbon';
import styles from './billboard.scss';
// const LoadableP5 = Loadable(() => import('react-p5-wrapper'));

const Billboard = ({ isVisible }) => {
	return (
		<div
			className="billboard contact-billboard regform title"
			style={{
				backgroundColor: '#F3DC4B',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center'
			}}
		>
			<div className="billboard__text">
				<h1 className="billboard__title">Contact</h1>
				<h3 className="billboard__caption">Get in touch</h3>
			</div>
		</div>
	);
};

export default Billboard;
