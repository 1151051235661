export default {
	uniforms: {
		tDiffuse: { value: null },
		tAdd: { value: null }
	},

	vertexShader: `
    varying vec2 vUv;
    void main() {
    vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }`,

	fragmentShader: `
    uniform sampler2D tDiffuse;
    uniform sampler2D tAdd;
    varying vec2 vUv;

    void main() {
      gl_FragColor = ( texture2D( tDiffuse, vUv ) + vec4( 1.0 ) * texture2D( tAdd, vUv ) );
      gl_FragColor.a =  texture2D( tDiffuse, vUv ).a; // THIS did it
    }
    `,
  defines: {}
};
