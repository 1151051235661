import React, { useContext } from 'react';
import { RegFormContext } from './index';
import Social from '../Social';
const Form = () => {
	const [ state, setState ] = useContext(RegFormContext);
	const onFocus = (e) => {
		// e.preventDefault();
		setState((state) => ({ ...state, isFocus: true }));
	};
	const onBlur = (e) => {
		// e.preventDefault();
		setState((state) => ({ ...state, isFocus: false }));
	};
	const onMouseEnter = (e) => {
		// e.preventDefault();
		setState((state) => ({ ...state, isHover: true }));
	};
	const onMouseLeave = (e) => {
		// e.preventDefault();
		setState((state) => ({ ...state, isHover: false }));
	};
	const onSubmit = (e) => {
		// e.preventDefault();
		setState((state) => ({ ...state, isSubmitted: true }));
	};

	return (
		<div className="all-in-one contact-info">
			{/* <Social /> */}
			<div className="contact-info__content">
				<div className="contact-info__title-wrapper">
					<h4 className="contact-info__title clickable inverted" onMouseEnter={onSubmit} onMouseLeave={onMouseLeave}>
						<span className="contact-info__target top me">henky.wu</span>
						<span>@htgmanics.com</span>
					</h4>
					<h4
						className="contact-info__title clickable inverted"
						onClick={(e) => {
							window.open('https://www.linkedin.com/in/htgmanics/');
						}}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
						onTouchStart={onMouseEnter}
						onTouchEnd={onMouseLeave}
					>
						<span>henky.wu@</span>
						<span className="contact-info__target top linkedin">htgmanics</span>
						<span>.com</span>
					</h4>
					<h4
						className="contact-info__title clickable inverted"
						onClick={(e) => {
							window.open('https://twitter.com/htgmanics');
						}}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
						onTouchStart={onMouseEnter}
						onTouchEnd={onMouseLeave}
					>
						<span>henky.wu</span>
						<span className="contact-info__target twitter">@htgmanics</span>
						<span>.com</span>
					</h4>
					<h4
						className="contact-info__title clickable inverted"
						onClick={(e) => {
							window.open('https://www.htgmanics.com');
						}}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
						onTouchStart={onMouseEnter}
						onTouchEnd={onMouseLeave}
					>
						<span>henky.wu@</span>
						<span className="contact-info__target website">htgmanics.com</span>
					</h4>
					<h4
						className="contact-info__title clickable inverted"
						onClick={(e) => {
							window.location.href = 'mailto:henky.wu@htgmanics.com';
						}}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
						onTouchStart={onMouseEnter}
						onTouchEnd={onMouseLeave}
					>
						<span className="contact-info__target top email">henky.wu@htgmanics.com</span>
					</h4>
				</div>
			</div>
		</div>
	);
};

export default Form;
