import React from 'react'
import styles from './guides.module.scss'

const Guides = () => {
	return (
		<div className={styles.guides}>
			{[ ...new Array(18) ].map((element, index) => <div key={index} className={styles.guides__column} />)}
		</div>
	)
}

export default Guides
