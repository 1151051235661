import React from "react"
import { useResource } from "react-three-fiber"
const Lights = () => {
  const [ref, light] = useResource()
  return (
    <>
      <ambientLight color="#fff" intensity={0.2} />
      <spotLight
        ref={ref}
        castShadow
        position={[100, 200, 100]}
        distance={5000}
        angle={0.2}
        penumbra={1}
        decay={2}
        intensity={0.8}
      />
      {false && <spotLightHelper args={[light]} />}
    </>
  )
}

export default Lights
