import React, { useState, useEffect } from 'react'
import { useUpdate, useThree } from 'react-three-fiber'
import { noise } from '../../../utils/perlin'
// import GUI from './GUI'

const Terrain = ({pulse}) => {
	const { size } = useThree()
	const { width } = { width: 28 } //GUI.useContainer()
	const [offset, setOffset] = useState(0)

	const mesh = useUpdate(({ geometry }) => {		
		// setOffset(offset => offset + 1)
		noise.seed(Math.random())
		let pos = geometry.getAttribute('position')
		let pa = pos.array
		const hVerts = geometry.parameters.heightSegments + 1
		const wVerts = geometry.parameters.widthSegments + 1
		for (let j = 0; j < hVerts; j++) {
			for (let i = 0; i < wVerts; i++) {
				const ex = 1.3; //Math.log10(j) *
				pa[3 * (j * wVerts + i) + 2] =(						
						noise.simplex2((i + offset)/ 100, (j + offset)/ 100) +
						noise.simplex2(((i + offset)+ 200) / 50, (j + offset)/ 50) * Math.pow(ex, 1) +
						noise.simplex2(((i + offset)+ 400) / 25, (j + offset)/ 25) * Math.pow(ex, 2) +
						noise.simplex2(((i + offset)+ 600) / 12.5, (j + offset)/ 12.5) * Math.pow(ex, 3) +
						noise.simplex2(((i + offset)+ 800) / 6.25, (j + offset)/ 6.25) * Math.pow(ex, 4)						
						) / 2;						
			}
		}
		pos.needsUpdate = true
	// }, [pulse])
	}, [])

	useEffect(() => {
		noise.seed(Math.random())
	}, [])
	
	return (
		<>
			<mesh ref={mesh} position={[0, 1, -10]} layers={0} receiveShadow>
				<planeBufferGeometry attach="geometry" args={[ width, width * size.height / size.width, 30, 30 ]} />
				{<meshPhongMaterial
					attach="material" 
					color={'#ccc'}
					specular={'#fff'}					
					shininess={10} 
					/>}
			</mesh>
		</>
	);
};

export default Terrain;
