import React from 'react'
import video from './billboard-bg-video.mp4'
import styles from './billboard.scss'

const Billboard = () => {
    return (
        <div className="billboard about-billboard">
            <video className="billboard__background video" autoPlay playsInline muted loop src={video} />
            <div className="billboard__text">
                <h5 className="billboard__subtitle">me?</h5>
                <h1 className="billboard__title">About</h1>
                <h3 className="billboard__caption">                    
                    Henky<br/><br/>Wu
                </h3>
            </div>
			<div className="about-identity__btn-click"><span>←</span> click me</div>
        </div>
    )
}

export default Billboard
