import React, { useEffect, useMemo } from 'react';
import * as THREE from 'three';
import { useBox } from 'use-cannon';
import { useFrame, useThree } from 'react-three-fiber';
// import niceColors from "nice-color-palettes"

const HD = { w: 8, h: 2, d: 4 };
const E_PIVOT = { x: 0.6, y: 5, z: -3 };

const randomData = (count, radius, size, scale, pos) => {
	return new Array(count).fill().map(() => {
		const offset = pos
			.clone()
			.add(
				new THREE.Vector3(
					HD.w / 2 - HD.w * Math.random(),
					-Math.random() * 2,
					1 * radius - Math.random() * radius * 2
				)
			);
		const speed = 0.1 + Math.random();
		const rotation = new THREE.Vector3(
			Math.sin(Math.random()) * Math.PI,
			Math.sin(Math.random()) * Math.PI,
			Math.cos(Math.random()) * Math.PI
		);
		return {
			scale: typeof scale === 'function' ? scale() : scale,
			size,
			rotation,
			offset,
			pos,
			speed,
			radius
		};
	});
};

const Particles = () => {
	const { viewport } = useThree();
	const dummy = useMemo(() => new THREE.Object3D(), []);
	const dummyMatrix = useMemo(() => new THREE.Matrix4(), []);
	const number = 150;
	const myColors = [ '#9B5DE5', '#F15BB5', '#FEE440', '#00BBF9', '#00F5D4', '#363537' ];
	const colors = useMemo(
		() => {
			const array = new Float32Array(number * 3);
			const color = new THREE.Color();
			const length = myColors.length - 1;
			for (let i = 0; i < number; i++)
				color.set(myColors[Math.floor(Math.random() * length)]).convertSRGBToLinear().toArray(array, i * 3);
			return array;
		},
		[]
	);

	const particles = useMemo(
		() =>
			randomData(
				number,
				2,
				2,
				() => 0.5 + Math.random() * 0.2,
				new THREE.Vector3(E_PIVOT.x, E_PIVOT.y, E_PIVOT.z)
			),
		[]
	);

	const [ cannonMesh, api ] = useBox((i) => {
		const { offset, scale, rotation } = particles[i];
		// console.log(scale);
		return {
			mass: 0.2 + scale,
			position: [ offset.x, offset.y, offset.z ],
			rotation: [ rotation.x, rotation.y, rotation.z ],
			velocity: [ 1 - Math.random() * 2, 10 + Math.random() * 15, Math.random() * 10 ],
			args: [ scale, scale, scale ]
		};
	});

	useEffect(
		() => {
			for (let i = 0; i < cannonMesh.current.count; i++) {
				const selectedCube = api.at(i);
				const subscription = selectedCube.position.subscribe((p) => {
					if (p[1] < -viewport.height * 4 / 5) {
						selectedCube.velocity.set(0, 0, 0);
						selectedCube.position.set(
							E_PIVOT.x + HD.w / 2 - HD.w * Math.random(), // viewport.width / 4 - (Math.random() * viewport.width) / 2,
							E_PIVOT.y + 1 - Math.random() * 2, // viewport.height / 2 + Math.random() * 50,
							E_PIVOT.z + Math.random() * 2
						);
						selectedCube.applyImpulse(
							[ 1 - Math.random() * 2, 10 + Math.random() * 15, Math.random() * 10 ],
							[ 0, 0, 0 ]
						);
					}
				});
			}
		},
		[ api ]
	);

	useFrame(() => {
		// if (!render) return;
		particles.forEach((particle, i) => {
			const { scale } = particle;
			cannonMesh.current.getMatrixAt(i, dummyMatrix);
			dummyMatrix.decompose(dummy.position, dummy.quaternion, dummy.scale);
			dummy.scale.set(scale, scale, scale);

			dummy.updateMatrix();

			cannonMesh.current.setMatrixAt(i, dummy.matrix);
		});
		cannonMesh.current.instanceMatrix.needsUpdate = true; //render;
	});

	return (
		<instancedMesh ref={cannonMesh} castShadow receiveShadow args={[ null, null, particles.length ]}>
			<boxBufferGeometry attach="geometry">
				<instancedBufferAttribute attachObject={[ 'attributes', 'color' ]} args={[ colors, 3 ]} />
			</boxBufferGeometry>
			<meshLambertMaterial attach="material" vertexColors={THREE.VertexColors} />
		</instancedMesh>
	);
};

export default Particles;
