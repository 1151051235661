import React from 'react';
import Wave from './Wave';
import P5Wrapper from 'react-p5-wrapper';
// const module = typeof window !== 'undefined' ? require('react-p5-wrapper') : null;
import DialVideo from './mail.mp4';
import styles from './video.module.scss';

const Video = () => {
	const { video, video__titleWrapper, video__title, video__background } = styles;
	return (
		<div
			className={`${video} clickable`}
			onClick={(e) => {
				window.location.href = 'mailto:henky.wu@htgmanics.com';
			}}
		>
			<video className={video__background} autoPlay playsInline muted loop src={DialVideo} />
			<div className={video__title} style={{ display: 'none' }}>
				<div>Hello</div>
				<span>...</span>
			</div>
		</div>
	);
};

export default Video;
