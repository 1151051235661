import React from 'react';
import styles from './download.scss';

const Download = () => {
	return (
		<div
			className="resume-download clickable"
			onClick={() => {
				window.open('https://www.linkedin.com/in/htgmanics/');
			}}
		>
			<div className="resume-download__wrapper">
				<div className="resume-download__title hidden">
					VIEW <span>my</span>
				</div>
				<div className="resume-download__title glitch" title="Linkedin">
					Linkedin
				</div>
				<div className="resume-download__title hidden">PROFILE</div>
			</div>
		</div>
	);
};

export default Download;
