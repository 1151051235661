import React from 'react';
import TwitterIcon from './twitter.inline.svg';
import styles from './me.scss';

const Me = () => {
	return (
		<div
			className="contact-page__me clickable"
			onClick={() => {
				window.open('https://twitter.com/htgmanics');
			}}
		>
			<img src={'/images/contact/me-circle.webp'} alt="this is Henky Wu's sideview" loading="lazy"/>
			<div className="typing-block">
				<div className="typing-dot" />
				<div className="typing-dot" />
				<div className="typing-dot" />
			</div>
		</div>
	);
};

export default Me;
