import React, { useRef, useEffect } from 'react';
import { useFrame, useThree, useResource } from 'react-three-fiber';
import { useSpring, a } from 'react-spring/three';

const KeyLight = ({ data }) => {
	const [ ref, light ] = useResource();
	useEffect(
		() => {
			ref.current.layers.enable(1);
		},
		[ ref ]
	);
	return (
		<a.spotLight ref={ref} distance={50} castShadow {...data}>
			{light && <a.spotLightHelper args={[ light, '#ccc' ]} />}
		</a.spotLight>
	);
};

const FillLight = ({ data }) => {
	const [ ref, light ] = useResource();
	useEffect(
		() => {
			ref.current.layers.enable(1);
		},
		[ ref ]
	);
	return (
		<a.spotLight ref={ref} distance={40} castShadow {...data}>
			{light && <a.spotLightHelper args={[ light, '#ccc' ]} />}
		</a.spotLight>
	);
};

const RimLight = ({ data }) => {
	const [ ref, light ] = useResource();
	return (
		<a.spotLight ref={ref} distance={50} {...data}>
			{false && <a.spotLightHelper args={[ light, 1, '#fff' ]} />}
		</a.spotLight>
	);
};

const RectHighLight = ({ mouse }) => {
	const { viewport, size } = useThree();
	const aspect = size.width / viewport.width;
	const [ ref, light ] = useResource();
	useEffect(
		() => {
			ref.current.layers.enable(1);
		},
		[ ref ]
	);
	useFrame(() => {
		var t = Date.now() / 2000;
		var r = 0.2;
		var zR = r * Math.sin(t);
		light.rotation.set(0, 0, zR);
		light.position.set(0, -mouse.current[1] / aspect, 0);
	});

	return (
		<rectAreaLight
			ref={ref}
			width={12}
			height={0.3}
			intensity={25}
			color="#00ddff"
			lookAt={[ 0, 0, 0 ]}
			position={[ 0, 0, 1.3 ]}
			rotation={[ 0, 0, 0 ]}
			castShadow
		>
			{false && <pointLightHelper args={[ light, 1 ]} />}
		</rectAreaLight>
	);
};

const random = ({ position, angle, penumbra, decay, intensity }) => {
	const palette = ["#ff0000","#ff8700","#ffd300","#deff0a","#a1ff0a","#0aff99","#0aefff","#147df5","#580aff","#be0aff"];
	/* [
		'#25CED1',
		'#FFFF',
		'#FCEADE',
		'#FF8A5B',
		'#EA526F',
		'#edf67d',
		'#f896d8',
		'#ca7df9',
		'#724cf9',
		'#564592'
	]; */
	return {
		position: [
			position[0] + Math.floor(4 - Math.random() * 8),
			position[1] + Math.floor(Math.random() * 4 - 1),
			position[2] + Math.floor(Math.random() * 8 - 4)
		],
		angle: angle + Math.random() * Math.PI / 3,
		penumbra: 0,
		decay:  Math.random() * (1 - decay) + decay,
		intensity: intensity * (Math.random() + 0.2),
		color: palette[Math.round(Math.random() * (palette.length - 1))]
	};
};

const Lights = ({ mouse, pulse }) => {
	const interval = 3000;
	const initLight1Params = {
		position: [ -4, 1, 12 ],
		angle: Math.PI / 6,
		penumbra: 1,
		decay: 0.3,
		intensity: 1,
		color: '#FFFFFF'
	};
	const initLight2Params = {
		position: [ 13.4, 5, 2.4 ],
		angle: Math.PI / 10,
		penumbra: 1,
		decay: 0.2,
		intensity: 0.6,
		color: '#ff006b'
	};
	const initLight3Params = {
		position: [ 0, 6, -10 ],
		angle: Math.PI / 3,
		penumbra: 1,
		decay: 1,
		intensity: 20,
		color: '#002f3d'
	};

	const [ light1, setlight1 ] = useSpring(() => random(initLight1Params));
	const [ light2, setlight2 ] = useSpring(() => random(initLight2Params));
	const [ light3, setlight3 ] = useSpring(() => random(initLight3Params));

	const moveLights = () => {
		setlight1(random(initLight1Params));
		setlight2(random(initLight2Params));
		setlight3(random(initLight3Params));
	};

	useEffect(() => void moveLights(), []);

	useEffect(() => void moveLights(), [ pulse ]);

	return (
		<group>
			{true && <ambientLight intensity={0.1} />}
			{true && <KeyLight data={light1} />}
			{true && <FillLight data={light2} />}
			{true && <RimLight data={light3} />}
		</group>
	);
};

export default Lights;
