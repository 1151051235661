import React, { useEffect, useState, useRef } from 'react'
import Hero from './Hero'
import Statement from './Statement'
import isInViewPort from '../../utils/isInViewPort'
import Guides from './Guides'
import Billboard from './Billboard'
import MoreButtons from './MoreButton'
import ScrollDown from './ScrollDown'
import Email from './Email'
import styles from './about.scss'

const Introduction = ({ isVisible, isCurrentLayer, inTransition, onMouseClick }) => {
	return (
		<div className="about-page__introduction abcde">
			<div className="g-grid__item a">
				{true && <Hero isVisible={isVisible} isCurrentLayer={isCurrentLayer} inTransition={inTransition} />}
			</div>
			<div className="g-grid__item b">{true && <Billboard />}</div>
			<div className="g-grid__item c" style={{ flexDirection: 'column' }}>
				{true && <Statement isVisible={isVisible} isCurrentLayer={isCurrentLayer}/>}
				<Guides />
			</div>
			<div className="g-grid__item d">{true && <ScrollDown />}</div>
			<div className="g-grid__item e" />
			<div className="g-grid__item f" />
			<div className="g-grid__item g" />
		</div>
	)
}

export default Introduction
