import React, { createContext, useEffect, useState } from 'react';
import Background from './Background';
import ContactInfo from './ContactInfo';
import styles from './all-in-one.scss';

export const RegFormContext = createContext([ {}, () => {} ]);

const RegForm = ({ isVisible }) => {
	const [ state, setState ] = useState({
		isFocus: false,
		isHover: false,
		isSubmitted: false
	});
	const [ isAppActive, setIsAppActive ] = useState(false);

	useEffect(
		() => {
			setIsAppActive(isVisible);
		},
		[ isVisible ]
	);
	
	return (
		<div
			className="all-in-one boids cursor-inverted"
			style={{ backgroundColor: '#fff' }}
			onMouseOver={(e) => {
				// setIsAppActive(true);
			}}
			onMouseOut={(e) => {
				// setIsAppActive(false);
			}}
		>
			<RegFormContext.Provider value={[ state, setState ]}>
				{/* <Background isVisible={isAppActive} /> */}
				<ContactInfo />
			</RegFormContext.Provider>
		</div>
	);
};

export default RegForm;
