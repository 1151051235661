import React from 'react'
import video from './8.mp4'
import './billboard.scss'

const Billboard = () => {
    return (
        <div className="billboard works-billboard">
            <video className="billboard__background video" autoPlay playsInline muted loop src={video} />
            <div className="billboard__text">
                <h6  className="billboard__subtitle">Selected</h6 >
                <h1 className="billboard__title">Works</h1>
                <h3 className="billboard__caption">                    
                    <span>2008</span>
                    <span>-</span>
                    <span>Now</span>
                </h3>
            </div>
        </div>
    )
}

export default Billboard
