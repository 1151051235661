import React from 'react'
import styles from './scrolldown.scss'

const ScrollDown = () => {
	return (
		<div className="about-scrolldown">
           
			{false && <img className="scrolldown__hand" src={'/images/about/hand-point-down.webp'} alt="" loading="lazy"/>}
            <div className="mouse"></div>
            <div className="scrolldown__cta">
                Scroll<br/>
                Down
            </div>
		</div>
	)
}

export default ScrollDown
