import React, { useState, useEffect, Suspense, memo } from 'react';
import { Canvas, useThree, extend, useFrame } from 'react-three-fiber';
import { Physics, usePlane, useSphere } from 'use-cannon';
import Lights from './Light';
// import Controls from './Controls';
import Letters from './Letters';
import Picasso from './Picasso';
import Particles from './Particles';
// import Effects from './Effects';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

extend({
	OrbitControls
});

const Mouse = () => {
	const { viewport } = useThree();
	const [ , api ] = useSphere(() => ({ type: 'Kinematic', args: 6 }));
	return useFrame((state) =>
		api.position.set(state.mouse.x * viewport.width / 2, state.mouse.y * viewport.height / 2, 0)
	);
};

const Plane = () => {
	const [ mesh ] = usePlane(() => ({ rotation: [ -Math.PI / 2, 0, 0 ] }));
	return (
		<mesh ref={mesh} receiveShadow>
			<planeBufferGeometry attach="geometry" args={[ 500, 500, 10 ]} />
			{true && <shadowMaterial attach="material" color="#151515" />}
		</mesh>
	);
};

const Hero = ({ isVisible, isCurrentLayer, inTransition }) => {
	const [ render, setRender ] = useState(true);
	const [ hover, setHover ] = useState(false);
	const [ loadCount, setLoadCount ] = useState(0);
	const TOTAL_ASSETS_NEEDS_TO_LOAD = 1;

	const loadCompleteHander = () => {
		setLoadCount((count) => count + 1);
	};

	useEffect(
		() => {
			if (loadCount < TOTAL_ASSETS_NEEDS_TO_LOAD) return;

			if (isVisible) {
				// stop rendering when in transition
				if (inTransition) setRender(false);
				// only render when the section is in current layer
				if (isCurrentLayer) setRender(true);
			} else {
				setRender(false);
			}
		},
		[ isVisible, isCurrentLayer, inTransition, loadCount ]
	);
	return (
		<div
			className="page__hero clickable"
			onMouseEnter={() => {
				// setHover(true);
			}}
			onMouseLeave={() => {
				// setHover(false);
			}}
			onMouseDown={() => {
				setHover(true);
			}}
			onMouseUp={() => {
				setHover(false);
			}}
			onTouchStart={() => {
				setHover(true);
			}}
			onTouchEnd={() => {
				setHover(false);
			}}
			aria-hidden="true"
		>
			<Canvas shadowMap sRGB camera={{ position: [ 0, 0, 50 ], fov: 45 }}>
				<Lights />
				{/* <Physics allowSleep step={1/960}> */}
				<Physics allowSleep>
					<Particles />
					<Picasso render={render} hover={hover} loadCompleteHander={loadCompleteHander} />
					<Suspense fallback={null}>
						{/* <Letters letters="MY IDEAS" position={[ 0, -12, 3 ]} loadCompleteHander={loadCompleteHander} /> */}
					</Suspense>
					{/* <Plane /> */}
				</Physics>
			</Canvas>
		</div>
	);
};
export default Hero;
