import React, { useEffect, useRef, useMemo, Fragment, useState } from 'react';
import * as THREE from 'three';
import { useFrame, useLoader } from 'react-three-fiber';
import { useSpring, a } from 'react-spring/three';
import { useBox } from 'use-cannon';
import bodyTexture from '../assets/picasso-body-nocover-glasses-gradient.webp';
import headTexture from '../assets/picasso-head-trim.webp';

const HD = { w: 8, h: 2, d: 4 };
const E_PIVOT = { x: 0.6, y: 5, z: -2 };

const Box = React.forwardRef(
	({ children, transparent = false, opacity = 1, color = 'goldenrod', args = [ 1, 1, 1 ], ...props }, ref) => {
		return (
			<mesh receiveShadow castShadow ref={ref} {...props} visible={false}>
				<boxBufferGeometry attach="geometry" args={args} />
				<meshStandardMaterial attach="material" color={color} transparent={transparent} opacity={opacity} />
				{children}
			</mesh>
		);
	}
);

const ParticlesBox = () => {
	const bottomPlane = {
		type: 'Static',
		position: [ E_PIVOT.x + 0, E_PIVOT.y - HD.h / 2, 0 - HD.d / 2 - 0.5 ],
		rotation: [ 0, 0, 0 ],
		args: [ HD.w, 0.1, HD.d ]
	};
	const frontPlane = {
		type: 'Static',
		position: [ E_PIVOT.x + 0, E_PIVOT.y - 6, 0 - 0.5 ],
		rotation: [ -Math.PI / 2, 0, 0 ],
		args: [ HD.w, 0.1, HD.h * 7 ]
	};
	const backPlane = {
		type: 'Static',
		position: [ E_PIVOT.x + 0, E_PIVOT.y + 1, -HD.d - 0.5 ],
		rotation: [ -Math.PI / 2, 0, 0 ],
		args: [ HD.w, 0.1, HD.h * 2 ]
	};
	const leftPlane = {
		type: 'Static',
		position: [ E_PIVOT.x - HD.w / 2, E_PIVOT.y + 1, 0 - HD.d / 2 - 0.5 ],
		rotation: [ 0, 0, -Math.PI / 2 ],
		args: [ HD.h * 2, 0.1, HD.d ]
	};
	const rightPlane = {
		type: 'Static',
		position: [ E_PIVOT.x + HD.w / 2, E_PIVOT.y + 0, 0 - HD.d / 2 - 0.5 ],
		rotation: [ 0, 0, -Math.PI / 2 ],
		args: [ HD.h, 0.1, HD.d ]
	};

	// const [bottom] = useBox(() => bottomPlane);
	const [ front ] = useBox(() => frontPlane);
	const [ back ] = useBox(() => backPlane);
	const [ left ] = useBox(() => leftPlane);
	const [ right ] = useBox(() => rightPlane);

	return (
		<Fragment>
			<Box ref={front} {...frontPlane} />
			<Box ref={back} {...backPlane} />
			<Box ref={left} {...leftPlane} />
			<Box ref={right} {...rightPlane} />
		</Fragment>
	);
};

const HeroBody = () => {
	const texture = useLoader(THREE.TextureLoader, bodyTexture);
	useMemo(() => (texture.minFilter = THREE.LinearFilter), []);
	return (
		<mesh receiveShadow>
			<planeBufferGeometry attach="geometry" args={[ 20, 20, 20 ]} />
			<meshLambertMaterial color="#ccc" attach="material" map={texture} side={THREE.DoubleSide} transparent />
		</mesh>
	);
};

const InnerHead = () => {
	var geometry = useMemo(() => {
		var points = [];
		var radius = 4.2;
		for (var i = 0; i < 15; i++) {
			points.push(new THREE.Vector2(Math.sin(i * 0.1) * 0.3 + radius, (i - 3) * 0.3));
		}
		return new THREE.LatheBufferGeometry(points, 9, 3, 1 * Math.PI);
	}, []);
	var material = useMemo(
		() =>
			new THREE.MeshLambertMaterial({
				color: 0x3df4f2,
				side: THREE.DoubleSide
			}),
		[]
	);
	return (
		<mesh
			castShadow
			receiveShadow
			rotation={[ -0.4, -Math.PI / 2 + 0.15, -0.8 ]}
			position={[ 0.45, 3.3, -1.5 ]}
			geometry={geometry}
			material={material}
		/>
	);
};
const HeroHead = ({ hover = false }) => {
	const texture = useLoader(THREE.TextureLoader, headTexture);
	useMemo(() => (texture.minFilter = THREE.LinearFilter), []);
	const mesh = useRef();
	const topPlane = {
		type: 'Static',
		position: [ 0.7, 7.8 + 3.5, -1.5 ],
		rotation: [ -Math.PI / 6, 0, 0 ],
		args: [ 9.3, 0.5, 3 ]
	};
	const [ top, api ] = useBox(() => topPlane);
	const counter = useRef(100);
	let max = 200, rate = 2;

	useFrame(({ clock }) => {
		if (!hover) counter.current = counter.current < max ? counter.current + rate : max;
		else counter.current = counter.current > 100 ? counter.current - rate : 100;
		const factor = 1; // 2 * counter.current / 100;
		const factor2 = 5 * counter.current / 100;

		api.position.set(0.7, 6.8 + factor2 + Math.sin(clock.getElapsedTime()) * factor, -1.5);
		mesh.current.position.set(0.7, 6.8 + 1 + factor2 + Math.sin(clock.getElapsedTime()) * factor, -1.5);
	});

	return (
		<group>
			<Box ref={top} {...topPlane} />
			<mesh ref={mesh} position={[ 0.7, 7.8 + 10, -1.5 ]} castShadow>
				<boxBufferGeometry attach="geometry" args={[ 9.3, 4.22, 3 ]} />
				<meshBasicMaterial attachArray="material" transparent opacity={0} />
				<meshBasicMaterial attachArray="material" transparent opacity={0} />
				<meshBasicMaterial attachArray="material" transparent opacity={0} />
				<meshBasicMaterial attachArray="material" transparent opacity={0} />
				<meshLambertMaterial
					color="#ccc"
					attachArray="material"
					map={texture}
					side={THREE.DoubleSide}
					transparent
				/>
				<meshBasicMaterial attachArray="material" transparent opacity={0} />
			</mesh>
		</group>
	);
};

const Picasso = ({ render, hover, loadCompleteHander }) => {
	useFrame(({ gl, scene, camera }) => render && gl.render(scene, camera), 1);
	useEffect(() => {
		loadCompleteHander();
	}, []);
	return (
		<Fragment>
			<ParticlesBox />
			<InnerHead />
			<HeroBody />
			<HeroHead hover={hover} />
		</Fragment>
	);
};

export default Picasso;
