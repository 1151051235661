import React, { forwardRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import Project from './Project';

const Introduction = forwardRef(({ isVisible, projects }, ref) => {
	const [ gridClass, setGridClass ] = useState('');

	useEffect(
		() => {
			const newGridClass = projects
				.filter((p, i) => i < 7)
				.map((p, i) => String.fromCharCode(65 + i).toLowerCase())
				.join('');
			setGridClass(newGridClass);
		},
		[ projects ]
	);

	return (
		<div className={classNames('projects-page__introduction', gridClass)}>
			{projects.filter((p, i) => i < 7)
				.map(({ node: { frontmatter: p } }, i) => (
					<div key={i} className={`g-grid__item ${String.fromCharCode(65 + i).toLowerCase()}`}>
						<Project
							image1={p.thumbnail[0]}
							image2={p.thumbnail[1]}
							displace="/images/projects/displacement-map.jpg"
							client={p.client}
							title={p.project}
							slug={p.slug}
							colors={p.colors}
							order={i}
						/>
					</div>
				))
			}
		</div>
	);
});

export default Introduction;
