import React, { Fragment, cloneElement, Children, useContext, useEffect, lazy } from 'react';
import Helmet from 'react-helmet';
import { InView } from 'react-intersection-observer';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { ProjectStateContext } from '../context/ProjectsContextProvider';
import About from '../sections/About';
import Works from '../sections/Works';
import Projects from '../sections/Projects';
import Contact from '../sections/Contact';
// const About = lazy(() => import('../sections/About'));
// const Works = lazy(() => import('../sections/Works'));
// const Projects = lazy(() => import('../sections/Projects'));
// const Contact = lazy(() => import('../sections/Contact'));

export const scrollSectionByUrlHash = (section) => {
	const urlHash = new URL(document.location).hash;
	const findPosition = (obj) => {
		var currenttop = 0;
		if (obj.offsetParent) {
			do {
				currenttop += obj.offsetTop;
			} while ((obj = obj.offsetParent));
			return [currenttop];
		}
	}
	if (urlHash === '#' + section) {
		var element = document.getElementById(section);
		// element.scrollIntoView(true);
		window.scroll(0, findPosition(element));
	}
};
// wrap all sections with inView component - watch if any sections are out of viewport
const Sections = ({ children, width }) => {
	const state = useContext(ProjectStateContext);
	return (
		<div className={`g-grid__${width}`}>
			{Children.map(children, (child, index) => (
				<InView key={index} threshold={0.6}>
					{({ inView, ref, entry }) => cloneElement(child, { ref, isVisible: inView, state })}
				</InView>
			))}
		</div>
	);
};

const IndexPage = () => {
	return (
		<Layout>
			<Helmet bodyAttributes={{ class: 'home' }} />
			<SEO title="Home" webgl />
			<Sections width="full">
				<About />
				<Works />
				<Projects />
				<Contact />
			</Sections>
		</Layout>
	);
};

export default IndexPage;
