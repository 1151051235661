import React, { useRef, useEffect } from 'react';
import hoverEffect from 'hover-effect';
import { navigate } from 'gatsby';
import styles from './project.scss';
import { useDarkMode } from '../../../projects/Template';

const Project = ({ image1, image2, displace, client, title, slug, colors, order }) => {
	const container = useRef(null);
	const route = client.replace(/\s+/g, '-').toLowerCase() + '-' + title.replace(/\s+/g, '-').toLowerCase();
	useEffect(
		() => {
			setTimeout(() => {
				const effect = new hoverEffect({
					parent: container.current,
					intensity: 1,
					image1,
					image2,
					displacementImage: displace
				});
			}, 0 + order * 50);
		},
		[ container ]
	);
	const gotoProjects = (e) => {
		// navigate('/projects/' + slug);
		window.location.href = `/projects/${slug}/`;
	};
	return (
		<div className="project-block" onClick={gotoProjects}>
			<div ref={container} className="project-block__slide " />
			<div className="project-block__title">
				<h6>{title}</h6>
				<h2 className="clickable">{client}</h2>
				<div
					className="project-block__viewmore"
					style={{
						'--background-color': colors[0],
						'--text-color': useDarkMode(colors[1]) ? '#fff' : '#000'
					}}
				>
					<span> view more </span>
					{/* <span>...me</span> */}
				</div>
			</div>
		</div>
	);
};

export default Project;
