import React from 'react';

const Description = ({ backgroundColor, color, description }) => {
	return (
		<div className="project-block project-template__description" style={{ backgroundColor }}>
			<div
				className="project-template__paragraph"
				style={{ color }}
				dangerouslySetInnerHTML={{ __html: description }}
			/>			
		</div>
	);
};

export default Description;
