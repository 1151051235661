import React, { Suspense, useMemo, useEffect } from 'react';
import * as THREE from 'three/src/Three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
// import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { SimplifyModifier } from 'three/examples/jsm/modifiers/SimplifyModifier';
import { useLoader, useUpdate, useThree } from 'react-three-fiber';
import Text from './Text';
// import GUI from './GUI';
import { useSpring, a } from 'react-spring/three';

const Model = ({ url, setRender, ...props }) => {
	const stl = useLoader(STLLoader, url, () => {
		// render 1st frame
		setRender(true);
	});

	const ref = useUpdate((self) => {
		const size = new THREE.Vector3();
		self.geometry.computeBoundingBox();
		self.geometry.boundingBox.getSize(size);
		// self.position.x = -size.x / 2;
		self.position.y = -1; // vAlign === 'center' ? -size.y / 2 : vAlign === 'top' ? 0 : -size.y
	}, []);

	return (
		<mesh ref={ref} scale={[ 0.05, 0.05, 0.05 ]} rotation={[ -Math.PI / 2, 0, Math.PI / 3 - 0.2 ]} castShadow>
			<meshLambertMaterial attach="material" roughness={1} color="#ffffff" metalness={0} />
			<bufferGeometry attach="geometry" {...stl} />
		</mesh>
	);
};
const David = ({ mouse, pulse, setRender }) => {
	// const { scale, x, y, z } = GUI.useContainer()
	// const texture = useMemo(() => new THREE.TextureLoader().load('./assets/texture/marble.jpg'), [])
	// const modifier = useMemo(() => new SimplifyModifier(), []);
	// const texture = useLoader(THREE.TextureLoader, './assets/texture/marble.jpg')
	const degree = mouse ? - Math.atan2(mouse.y, mouse.x) * (180 / Math.PI) : 0;
	const degree2 = mouse ? - Math.atan2(mouse.x, mouse.y) * (180 / Math.PI) : 0;

	const map = (num, in_min, in_max, out_min, out_max) =>
		(num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
	const getAngle = (angle) => THREE.Math.degToRad(angle);
	const randomPos = () => ({
		rotation: [
			mouse ? getAngle(map(-Math.abs(degree2), -180, 0, -25, 20)) : getAngle(map( Math.random() * 20, 0, 20, -10, 15 )),
			mouse ? getAngle(map(-Math.abs(degree), -180, 0, -50, 50)) : getAngle(map( Math.random() * 70, 0, 70, -35, 35 )),
			0
		]
	});
	const [ props, setProps ] = useSpring(() => ({
		rotation: [ getAngle(0), getAngle(0), 0 ]
	}));

	useEffect(() => {
		// setTimeout(() => setRender(false), 3000);
		// setRender(false)
	}, []);

	useEffect(
		() => {
			setProps(randomPos());
		},
		[ pulse, mouse ]
	);

	return (
		<a.group {...props}>
			<Suspense fallback={<Model url={'/models/head-of-david-s256.stl'} setRender={setRender} />}>
				<Model url={'/models/head-of-david-s32.stl'} setRender={setRender} />
			</Suspense>
			<Suspense fallback={null}>
				<group>
					<Text
						hAlign="center"
						position={[ -0.35 - 0.3, 0.7 - 1.44, 2.4 + 0.77 ]}
						rotation={[ 0.5, 0.1, 0 ]}
						layers={0}
						children="o"
					/>
					<Text
						hAlign="center"
						position={[ 0.9 - 0.3, 0.75 - 1.44, 2.3 + 0.77 ]}
						rotation={[ 0.5, 0.1, 0 ]}
						layers={0}
						children="o"
					/>
					<Text
						hAlign="center"
						position={[ -0.35 - 0.3, 0.7 - 1.44, 2.4 + 0.77 ]}
						rotation={[ 0.5, 0.1, 0 ]}
						layers={1}
						children="o"
					/>
					<Text
						hAlign="center"
						position={[ 0.9 - 0.3, 0.75 - 1.44, 2.3 + 0.77 ]}
						rotation={[ 0.5, 0.1, 0 ]}
						layers={1}
						children="o"
					/>
				</group>
			</Suspense>
		</a.group>
	);
};

export default David;
