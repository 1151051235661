import React from 'react';
import Email from './Resume/Email';
import Back from './Resume/Back';
import Billboard from './Resume/Billboard';
import Download from './Resume/Download';
import Skills from './Resume/Skills';

const Resume = ({ onMouseClick }) => {
	return (
		<div className="about-page__resume abcde">
			<div className="g-grid__item a">
				<Skills />
			</div>
			<div className="g-grid__item b">{true && <Billboard />}</div>
			<div className="g-grid__item c">
				<Download />
			</div>
			<div className="g-grid__item d" onClick={onMouseClick('prev')}>
				<Back topCTA={' go'} bottomCTA={'< back\u00A0\u00A0'} />
			</div>
			<div className="g-grid__item e">
				<Email />
			</div>
			<div className="g-grid__item f" />
			<div className="g-grid__item g" />
		</div>
	);
};

export default Resume;
