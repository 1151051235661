import React, { forwardRef } from 'react';
import Description from './Description';
import ArrowIcon from './arrow-left.inline.svg';
import styles from './project-template.scss';
import Showcase from './Showcase';

export const useDarkMode = (color) => {
	const fromHexToRgb = color.replace('#', '').match(/.{1,2}/g);
	const r = parseInt(fromHexToRgb[0], 16);
	const g = parseInt(fromHexToRgb[1], 16);
	const b = parseInt(fromHexToRgb[2], 16);
	const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
	return luminance < 186;
};

const Template = forwardRef(({ isVisible, isCurrentLayer, project }, ref) => {
	const backgroundGradient = (color1, color2) => {
		return {
			background: `radial-gradient(
			at 25% 25%,
			${color1} 0%, ${color2} 180%
		  )`
		};
	};

	const {
		slug,
		banner,
		client,
		project: projectTitle,
		type,
		year,
		colors,
		technology,
		description,
		buttonTitle,
		url,
		thumbnail
	} = project;

	return (
		<div className="projects-page__introduction abcde">
			<div className="g-grid__item a">
				<div className="project-block project-template__showcase">
					<img src={banner} alt="" style={{ width: '100%' }} />
				</div>
				{/* <Showcase slides={thumbnail} /> */}
			</div>
			<div className="g-grid__item b" style={backgroundGradient(colors[0], colors[1])}>
				<div className="project-block project-block__title project-template__titles">
					<h1>{client}</h1>
					<h5>{projectTitle}</h5>
					<h5>{year}</h5>
					<h6 className="project-title__technology">{technology.join(' / ')}</h6>
				</div>
			</div>
			<div className="g-grid__item c">
				<Description
					backgroundColor={colors[1]}
					color={useDarkMode(colors[1]) ? '#FFFFFF' : '#000000'}
					description={description}
				/>
			</div>
			<div className="g-grid__item d">
				<div className="project-block project-template__demo">
					<div className="project-block__title" style={{ display: 'none' }}>
						<h3 style={{ color: useDarkMode(colors[2]) ? '#FFFFFF' : '#000000' }}>{year}</h3>
						<h3 style={{ color: useDarkMode(colors[2]) ? '#FFFFFF' : '#000000' }}>{type}</h3>
						<h3 style={{ color: useDarkMode(colors[2]) ? '#FFFFFF' : '#000000' }}>{`------->`}</h3>
					</div>
					<div
						className="resume-download"
						onClick={(e) => {
							if (typeof window !== 'undefined' && document) window.open(url);
						}}
					>
						<div className="resume-download__wrapper" style={{ backgroundColor: colors[0] }}>
							<div
								className="resume-download__title hidden"
								style={{ color: useDarkMode(colors[0]) ? '#FFFFFF' : '#000000' }}
							>
								VIEW <span />
							</div>
							<div
								className={`resume-download__title ${isCurrentLayer ? 'glitch' : ''}`}
								title={buttonTitle}
								style={{
									color: useDarkMode(colors[0]) ? '#FFFFFF' : '#000000',
									'--glitchbg-after': colors[2],
									'--glitchbg-before': colors[2] + '40'
								}}
							>
								{buttonTitle}
							</div>
							<div
								className="resume-download__title hidden"
								style={{ color: useDarkMode(colors[0]) ? '#FFFFFF' : '#000000' }}
							>
								HERE
							</div>
							<div className="resume-download__icon" style={{'--arrow-right-color': useDarkMode(colors[0]) ? '#FFFFFF' : '#000000'}}>
								<ArrowIcon />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="g-grid__item e" />
			<div className="g-grid__item f" />
			<div className="g-grid__item g" />
		</div>
	);
});

export default Template;
