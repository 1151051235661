import * as THREE from 'three';
import React, { useRef, useMemo } from 'react';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { GlitchPass } from "three/examples/jsm/postprocessing/GlitchPass"
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import AdditiveBlendingShader from './AdditiveBlendingShader'
import VolumetricLightShader from './VolumetricLightShader'
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader'
import { RGBShiftShader } from 'three/examples/jsm/shaders/RGBShiftShader';
import { extend, useThree, useFrame } from 'react-three-fiber';
import { useEffect } from 'react';
import { AdditiveBlending } from 'three';
extend({ EffectComposer, RenderPass, ShaderPass, GlitchPass, UnrealBloomPass });

const Effects = ({render}) => {
	const { gl, scene, camera, size } = useThree();
    const finalComposer2 = useRef()
    const finalComposerRenderTarget2 = useMemo(() => new THREE.WebGLRenderTarget(), [])
    const bloomComposer2 = useRef()
    const bloomRenderTarget2 = useMemo(() => new THREE.WebGLRenderTarget(), [])
    
	// const aspect = useMemo(() => new THREE.Vector2(size.width, size.height), [ size ])
    const aspect = useMemo(() => new THREE.Vector2(512, 512), [])
    
    const renderScene = useMemo(() => new RenderPass(scene, camera), [scene, camera]);

    const bloomPass = useMemo(() => {
        const params = {
            exposure: 2,
            bloomStrength: 3,
            bloomThreshold: 0,
            bloomRadius: 1
        };
        let bloomPass = new UnrealBloomPass(
            new THREE.Vector2(window.innerWidth, window.innerHeight),
            1.5,
            0.4,
            0.85
            );
        bloomPass.threshold = params.bloomThreshold;
        bloomPass.strength = params.bloomStrength;
        bloomPass.radius = params.bloomRadius;
        return bloomPass
    }, []);

    const bloomComposer = useMemo(() => {
        let bloomComposer = new EffectComposer(gl);
        bloomComposer.renderToScreen = false;
        bloomComposer.setSize(size.width, size.height);
        bloomComposer.addPass(renderScene);
        bloomComposer.addPass(bloomPass);
        return bloomComposer
    }, [gl, size, renderScene, bloomPass]);

    const finalPass = useMemo(() => {
        let finalPass = new ShaderPass(
            new THREE.ShaderMaterial({
                uniforms: {
                    tDiffuse: { value: null },
                    tAdd: { value: bloomComposer.renderTarget2.texture }
                },
            
                vertexShader: `
                varying vec2 vUv;
                void main() {
                vUv = uv;
                  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
                }`,
            
                fragmentShader: `
                uniform sampler2D tDiffuse;
                uniform sampler2D tAdd;
                varying vec2 vUv;
            
                void main() {
                  gl_FragColor = ( texture2D( tDiffuse, vUv ) + vec4( 1.0 ) * texture2D( tAdd, vUv ) );                  
                  gl_FragColor.a =  texture2D( tDiffuse, vUv ).a; 
                }
                `,
              defines: {}
            }), "tDiffuse");
        finalPass.needsSwap = true;
        return finalPass
    }, [bloomComposer]);

    const finalComposer = useMemo(() => {
        let finalComposer = new EffectComposer(gl);
        finalComposer.setSize(size.width, size.height);
        finalComposer.addPass(renderScene);
        finalComposer.addPass(finalPass);
        return finalComposer
    }, [gl, size, renderScene, finalPass]);

    
	useEffect(
		() => {
            // bloomComposer.current.setSize(size.width, size.height)
            // finalComposer.current.setSize(size.width, size.height)
            // gl.toneMapping = THREE.ReinhardToneMapping;
            // gl.toneMappingExposure = Math.pow( 1.2, 4.0 );
		},
		[ size, gl ]
	);

	useFrame(() => {
        if (render) {
            camera.layers.set(1);
            bloomComposer.render();
            camera.layers.set(0);
            finalComposer.render();    
        }
	}, 1);

	return (
        <>
            <effectComposer ref={bloomComposer2} args={[ gl, bloomRenderTarget2 ]} renderToScreen={true}>
                <renderPass attachArray="passes" args={[ scene, camera ]} />          
            </effectComposer>
            <effectComposer ref={finalComposer2} args={[ gl, finalComposerRenderTarget2 ]}>
                <renderPass attachArray="passes" args={[ scene, camera ]} />                
                <shaderPass attachArray="passes" args={[AdditiveBlendingShader]} uniforms-tAdd-value={bloomRenderTarget2.texture} renderToScreen needsSwap={true}/>
            </effectComposer>   
        </>
	);
};

export default Effects;
