import * as THREE from 'three';
import React, { useMemo } from 'react';
import { useLoader, useUpdate } from 'react-three-fiber';
// import GUI from './GUI';

export default function({ children, vAlign = 'center', hAlign = 'center', size = 1, color = '#000000', layers = 0, ...props }) {
	// const { factor } = GUI.useContainer();
	const font = useLoader(THREE.FontLoader, '/fonts/loaders/Major_Mono_Display_Regular.json');
	const config = useMemo(() => ({ font, size: 12, height: 1, curveSegments: 2 }), [ font ]);
	const mesh = useUpdate(
		(self) => {
			const size = new THREE.Vector3();
			self.geometry.computeBoundingBox();
			self.geometry.boundingBox.getSize(size);
			self.position.x = hAlign === 'center' ? -size.x / 2 : hAlign === 'right' ? 0 : -size.x;
			self.position.y = vAlign === 'center' ? -size.y / 2 : vAlign === 'top' ? 0 : -size.y;
		},
		[ children ]
	);
	return (
		<group {...props} scale={[ 0.1 * size, 0.1 * size, 0.001 ]}>
			<mesh ref={mesh} layers={layers}>
				<textGeometry attach="geometry" args={[ children, config ]} />
				<meshToonMaterial attach="material" color="#fae990"/>
			</mesh>
		</group>
	);
}
