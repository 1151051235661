import React from 'react';

const Wave = (p) => {
	let dx = 0,
		dy = 0;

	p.setup = () => {
		const dimension = {
			w: document.querySelector('.regform.title').offsetWidth,
			h: document.querySelector('.regform.title').offsetWidth
		};
		dx = Math.floor(dimension.w / 10);
        dy = Math.floor(dimension.h / 10);
		p.createCanvas(dimension.w, dimension.h);
		p.fill('#FFF');
		p.noStroke();
		p.rectMode(p.CENTER);
		p.frameRate(30);
		p.noiseDetail(2, 0.9);
	};

	p.draw = () => {
		p.background('#EE6C62');
		for (let x = dx; x < p.width; x += dx) {
			for (let y = dy; y < p.height; y += dy) {
                let n = p.noise(x * 0.005, y * 0.005, p.frameCount * 0.005);
				p.push();
				p.translate(x, y);
				p.rotate(p.PI * n);
				p.scale(dx * n);
				p.rect(0, 0, 1, 1);
				p.pop();
			}
        }
        // p.noLoop();
	};
};

export default Wave;
