import React, { forwardRef, useEffect } from 'react';
import Sections from '../index';
import Main from './Main';
// import styles from './contact.scss'
import { scrollSectionByUrlHash } from '../../pages/';

const Contact = forwardRef(({ isVisible }, ref) => {
	const id = "contact-page";

	useEffect(() => {
		scrollSectionByUrlHash(id);	
	}, []);

	return (
		<div className="contact-page page" id={id} ref={ref}>
			<Sections start={0} loop={false} reverse={false} isVisible={isVisible}>
				<Main />
			</Sections>
		</div>
	);
});

Contact.displayName = 'Contact';

export default Contact;
