import React, { forwardRef, useEffect } from 'react';
import Loading from '../../components/loading';
import Sections from '../index';
import Introduction from './Introduction';
import Resume from './Resume';
import { scrollSectionByUrlHash } from '../../pages/';

const About = forwardRef(({ isVisible }, ref) => {
	const id = 'about-page';

	useEffect(() => {
		scrollSectionByUrlHash(id);
	}, []);

	return (
		<div className="about-page page" id={id} ref={ref}>
			<Sections start={0} loop={false} isVisible={isVisible}>
				<Introduction />
				<Resume />
			</Sections>
		</div>
	);
});

About.displayName = 'About';

export default About;
