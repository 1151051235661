import React from 'react'
import styles from './back.scss'

const Back = ({topCTA, bottomCTA}) => {
	return (
		<div className="resume-back clickable">
			<div className="back__cta">{topCTA}</div>
			<img className="back__hand" src={'/images/about/hand-point-down.webp'} alt="" loading="lazy"/>
			<div className="back__cta">{bottomCTA}</div>
		</div>
	)
}

export default Back
