import * as THREE from 'three'
import { useLoader } from 'react-three-fiber'
import React, { useMemo } from 'react'
import GUI from './GUI';

const Name = () => {
    // const {x, y, z} = GUI.useContainer()
    const font = useLoader(THREE.FontLoader, '/fonts/loaders/Supply_UltraLight_Regular.json')
    const config = useMemo(() => ({
        font: font,
		size: 5, // 8
        height: 0.8,
        curveSegments: 24
    }), font)

    return (
        <group>
            <mesh castShadow position={[ -10, 4, -3 ]}>
                <meshPhongMaterial attach="material" color={'red'} transparent opacity={0} />
                <textBufferGeometry attach="geometry" args={['Henky', config]}/>
            </mesh>
            <mesh castShadow position={[ -7,-5, -3 ]}>
                <meshPhongMaterial attach="material" color={'red'} transparent opacity={0} />
                <textBufferGeometry attach="geometry" args={['Wu', config]}/>
            </mesh>
        </group> 
    )
}

export default Name
