import { identity } from 'lodash';
import React, { useEffect, useRef } from 'react';
import Typewriter from 'typewriter-effect';
import styles from './statement.scss';

const Statement = ({ isVisible, isCurrentLayer }) => {
	const IDENTITIES = [
		'Henky Wu',
		'Web Developer',
		'Creative Strategist',
		'Tinker',
		'Rebel',
		'Porter',
		'Alchemist',
		'Father'
	];
	const ref = useRef();
	useEffect(
		() => {
			console.log(ref.current);
		},
		[ isVisible, isCurrentLayer ]
	);
	return (
		<div className="about-identity">
			<div className="about-identity__btn-click">
				<span>←</span> click me
			</div>
			<div className="about-identity__title">
				<Typewriter
					ref={ref}
					onInit={(typewriter) => {
						let t = typewriter;
						IDENTITIES.forEach((identity, index) => {
							let isVowel = [ 'a', 'e', 'i', 'o', 'u' ].includes(identity[0].toLowerCase());
							let isFirst = index === 0;
							t = t
								.typeString(
									`${isFirst ? `I'm ` : ``}${isFirst ? `` : isVowel ? 'an ' : 'a '}${identity}`
								)
								.pauseFor(2500)
								.deleteChars(identity.length + (isFirst ? 0 : isVowel ? 3 : 2));
						});
						t = t.start();
					}}
					options={{
						autoStart: true,
						loop: true,
						delay: 60,
						deleteSpeed: 20
					}}
				/>
			</div>
		</div>
	);
};

export default Statement;
