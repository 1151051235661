import React, { useRef, useMemo, useEffect } from 'react';
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { extend, useThree, useFrame } from 'react-three-fiber';
// import GUI from './GUI';

extend({ OrbitControls });

const Controls = (props) => {
	// const {x, y, z} = GUI.useContainer()
	const ref = useRef();
	const target = useMemo(() => new THREE.Vector3(0, -1.76, -0.11), [])
	const { scene, camera, gl: { domElement }, gl } = useThree();
	useEffect(() => {
		if (ref.current) {
			ref.current.update()
			// ref.current.addEventListener( 'change', () => gl.render( scene, camera ) );
		}
	}, [])
	
	const setAngle = function (phi, theta, distance) {
		const controls = ref.current
		var r = distance || controls.object.position.distanceTo(controls.target);

		var x = r * Math.cos(phi - Math.PI / 2) * Math.sin(theta) + controls.target.x;
		var y = r * Math.sin(phi + Math.PI / 2) + controls.target.y;
		var z = r * Math.cos(phi - Math.PI / 2) * Math.cos(theta) + controls.target.z;

		controls.object.position.set(x, y, z);
		controls.object.lookAt(controls.target);

	};
	
	useFrame(({ clock, camera }) => {
		// camera.updateProjectionMatrix(void (camera.position.z = 50 + Math.sin(clock.getElapsedTime()) * 30))
		// setAngle(1.54 + Math.sin(clock.getElapsedTime()) * 0.01,  Math.sin(clock.getElapsedTime()) * 0.02);
	})
	
	

	return <orbitControls ref={ref} target={target} args={[ camera, domElement ]} {...props} />;
};

export default Controls;
