import React, { useRef, useEffect, useCallback, useState, Suspense, lazy } from 'react';
import { Canvas, useFrame } from 'react-three-fiber';
import Controls from './Controls';
import Lights from './Lights';
import Terrain from './Terrain';
import Effects from './Effects';
import David from './David';
// import GUI from './GUI';
import Name from './Name';
import useInterval from '../../../utils/UseInterval';
import styles from './hero.scss';
import Dummy3D from './Dummy3D';

const Hero = ({ isVisible, isCurrentLayer, inTransition }) => {
	const [ hover, setHover ] = useState(false);
	const [ count, setCount ] = useState(0);
	const [ pulse, setPulse ] = useState(false);
	const [ render, setRender ] = useState(true);
	const [mouse, setMouse] = useState(null);
	// const mouse = useRef(null);
	const canvasRef = useRef(null);
	// const onMouseMove = useCallback(
	const onMouseDown = useCallback(({ clientX: x, clientY: y }) => {
		if (typeof window !== 'undefined' && document)
			// mouse.current = { x: x - canvasRef.current.offsetWidth / 2, y: y - canvasRef.current.offsetHeight / 2 };
			setMouse({ x: x - canvasRef.current.offsetWidth / 2, y: y - canvasRef.current.offsetHeight / 2 });
	}, []);

	const CLOCK_INTERVAL = 3000;

	useInterval(() => {
		if (render) {
			setMouse(null);
			setPulse((pulse) => !pulse);			
		}
		// if (hover) setPulse((pulse) => !pulse);
	}, CLOCK_INTERVAL);

	useEffect(
		() => {
			// mouse.current = [ window.innerWidth / 2, window.innerHeight / 2 ];

			if (isVisible) {
				// stop rendering when in transition
				if (inTransition) setRender(false);
				// only render when the section is in current layer
				if (isCurrentLayer) setRender(true);
			} else {
				setRender(false);
			}
		},
		[ isVisible, isCurrentLayer, inTransition ]
	);

	return (
		<div
			className="page__hero"
			ref={canvasRef}
			onMouseEnter={() => {
				// setHover(true);
				// setRender(true);
				// setPulse((pulse) => !pulse);
			}}
			onMouseLeave={() => {
				// setHover(false);
				// setRender(false);
			}}
		>
			<Canvas
				className="page__3d"
				shadowMap
				camera={{ fov: 45, position: [ 0, -1.58, 26 ], rotation: [ 0, 0, 0 ] }}
				gl={{ antialias: false, alpha: false }}
				resize={{ scroll: false, debounce: { scroll: 0, resize: 0 } }}
				// onMouseMove={onMouseMove}
				// onMouseDown={() => setCount(count + 1)}
				onMouseDown={onMouseDown}
			>
				{/* <GUI.Provider> */}
				{/* <Controls autoRotateSpeed={3} /> */}
				<Lights mouse={mouse} pulse={pulse} />
				<Terrain pulse={pulse} />
				{true && (
					<Suspense fallback={null}>
						<David mouse={mouse} pulse={pulse} setRender={setRender} />
						<Name />
					</Suspense>
				)}
				<Effects render={render} />
				{/* </GUI.Provider> */}
			</Canvas>
			{false && <Dummy3D render={render} />}
		</div>
	);
};

export default Hero;
