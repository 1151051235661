import React from 'react';
import rabbitImg from './rabbit.png';
import { motion } from 'framer-motion';

const Rabbit = () => {
	return (
		<motion.img
			initial={{ y: '-110%', scaleY: 1, rotate: 0 }}
			animate={{ y: '500%', scaleY: 1.5, rotate: 10 - Math.random() * 20 }}
			transition={{ ease: [0.895, 0.03, 0.685, 0.22], duration: 1 + Math.random() * 0.5 }}
			src={rabbitImg}
			alt=""
		/>
	);
};

export default Rabbit;
