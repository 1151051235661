import React, { forwardRef, useEffect } from 'react';
import Sections from '../index';
import Introduction from './Introduction';
import './works.scss';
import { scrollSectionByUrlHash } from '../../pages/';

const Works = forwardRef(({ isVisible }, ref) => {
	const id = 'works-page';

	useEffect(() => {
		scrollSectionByUrlHash(id);
	}, []);

	return (
		<div className="works-page page" id={id} ref={ref}>
			<Sections start={0} loop={false} reverse={true} isVisible={isVisible}>
				<Introduction />
			</Sections>
		</div>
	);
});

Works.displayName = 'Works';

export default Works;
