import React from 'react';
import DialVideo from './say-hello-s.mp4';
import styles from './video.module.scss';

const Video = () => {
	const { video, video__titleWrapper, video__title, video__background } = styles;
	return (
		<div className={video}>
			<video className={video__background} autoPlay playsInline muted loop src={DialVideo} />
			<div className={video__title} style={{ display: 'none' }}>
				<div>Hello</div>
				<span>...</span>
			</div>
		</div>
	);
};

export default Video;
