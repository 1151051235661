import React, { useMemo, useEffect } from "react"
import * as THREE from "three"
import { useLoader } from "react-three-fiber"
import { useBox, useSphere, usePointToPointConstraint } from "use-cannon"

const Letter = ({ position, geometry, pivot, size, color }) => {
  const [mesh] = useBox(() => ({
    mass: 10,
    position: position,
    args: [size.x, size.y, size.z]
  }))
  const [anchor] = useSphere(() => ({
    type: "Static",
    args: 0,
    position: [position[0], position[1] + 2, position[2]]
  }))

  usePointToPointConstraint(anchor, mesh, {
    pivotA: [0, 0, 0],
    pivotB: [0, 2, 0]
  })

  return (
    <group>
      <mesh ref={anchor} visible={false}>
        <sphereBufferGeometry attach="geometry" args={[0.5, 10, 10]} />
        <meshPhongMaterial attach="material" color="#151515" shininess={5} />
      </mesh>
      <mesh ref={mesh} castShadow receiveShadow geometry={geometry}>
        <meshLambertMaterial attach="material" color={color} />
      </mesh>
    </group>
  )
}
const createTextGeometry = (configs) => (letter) => {
  const textGeometry = new THREE.TextGeometry(letter, configs)
  textGeometry.computeBoundingBox()
  textGeometry.center()
  const size =
    textGeometry?.boundingBox?.getSize(new THREE.Vector3()) ||
    new THREE.Vector3()
  return { textGeometry, size }
}

const Letters = ({ letters = "", position = [0, 0, 0], loadCompleteHander }) => {
  const font = useLoader(THREE.FontLoader, "/fonts/loaders/Major_Mono_Display_Regular.json")
  const configs = useMemo(
    () => ({
      font,
      size: 4,
      height: 1,
      curveSegments: 24
    }),
    [font]
  )
  const letterSpacing = 0.5
  const textGeometries = [...letters].map(createTextGeometry(configs))
  const textOffsets = textGeometries.reduce((acc, curr, index, array) => {
    const currLetterWidth = curr.size.x
    const prevLetterWidth = index ? array[index - 1].size.x : 0
    const distanceFromCenter = index
      ? acc[acc.length - 1] +
        currLetterWidth / 2 +
        prevLetterWidth / 2 +
        letterSpacing
      : 0
    acc.push(distanceFromCenter)
    return acc
  }, [])
  const fullWidth = [...textOffsets].pop() || 15 // full width of the letters
  
  useEffect(() => {
    loadCompleteHander();
  }, [])

  return (
    <group>
      {textGeometries.map(({ textGeometry, size }, i) => (
        <Letter
          position={[
            position[0] + textOffsets[i] - fullWidth / 2,
            position[1] + 0,
            position[2] + 0
          ]}
          size={size}
          pivot={textOffsets[i]}
          geometry={textGeometry}
          color={0xffffff}
          key={i}
        />
      ))}
    </group>
  )
}

export default Letters
