import React from 'react';
import video from './post-office.mp4';
import styles from './email.scss';
import EmailText from './text.inline.svg';
const Email = () => {
	const { email, email__titleWrapper, email__title, email__background } = styles;
	return (
		<div
			className="resume-email clickable"
			onClick={() => {
				// window.open('https://www.linkedin.com/in/htgmanics/');
				var element = document.getElementById("contact-page");
				element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
			}}
		>
			<video className="resume-email__background" autoPlay playsInline muted loop src="video" />
			<div className="resume-email__title">
				<div>Contact</div>
				<span>...me</span>
			</div>
		</div>
	);
};

export default Email;
