import React, { useEffect, useRef, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { AnimatePresence, motion } from 'framer-motion';
import { useDebounce } from 'react-use';
import uuid from 'react-uuid';
import Rabbit from './Rabbit';
import video from './s.mp4';
import ArrowSVG from './arrow.inline.svg';
import rabbitImg from './rabbit.png';
import './rabbithole.scss';

const remove = (arr, item) => {
	const newArr = [ ...arr ];
	newArr.splice(newArr.findIndex((i) => i === item), 1);
	return newArr;
};

const add = (arr) => {
	return [ ...arr, uuid() ];
};

const RabbitHole = () => {
	const ref = useRef();
	const [ rabbits, setRabbits ] = useState([]);
	const [ debounceRabbits, setDebounceRabbits ] = useState([]);
	const [ height, setHeight ] = useState(0);
	const [ , cancel ] = useDebounce(
		() => {
			const newRabbits = [ ...new Array(1) ].map(() => uuid());
			setDebounceRabbits([ ...debounceRabbits, ...newRabbits ]); //[...debounceRabbits, ...newRabbits]);
		},
		30,
		[ rabbits ]
	);

	const bornRabbit = (e) => {
		setRabbits(add(rabbits));
	};
	const { allMarkdownRemark: { edges: projects } } = useStaticQuery(graphql`
		query ProjectsListSlugOnly {
			allMarkdownRemark {
				edges {
					node {
						frontmatter {
							slug
						}
					}
				}
			}
		}
	`);
	const gotoRandomProjects = () => {
		const seed = Math.floor(projects.length * Math.random());
		const slug = projects[seed].node.frontmatter.slug;
		window.location.href = `/projects/${slug}/`;
	}
	useEffect(() => {
		setHeight(ref.current.clientHeight);
	}, []);

	return (
		<div
			ref={ref}
			className="rabbithole works-rabbithole clickable"
			onTouchStart={(e) => {
				e.preventDefault();
				// gotoRandomProjects();
				bornRabbit();
			}}
			onClick={(e) => {
				e.preventDefault();
				gotoRandomProjects();
				// bornRabbit();
			}}
			onMouseEnter={(e) => {
				e.preventDefault();
				bornRabbit();
			}}
			onMouseLeave={(e) => {
				e.preventDefault();
				bornRabbit();
			}}
			// onMouseMove={(e) => {
			// 	e.preventDefault();
			// 	e.stopPropagation();
			// 	bornRabbit();
			// }}
			aria-hidden="true"
		>
			<video className="rabbithole__background video" autoPlay playsInline muted loop src={video} />
			<div className="rabbithole__text">
				<h3 className="rabbithole__caption" style={{ zIndex: 10 }}>
					<span>Follow</span>
					<span>me</span>
				</h3>
				<h1 className="rabbithole__title" style={{ zIndex: 30 }}>
					Down the
				</h1>
				<div className="rabbithole__scene" style={{ zIndex: 20 }}>
					<div className="scene__rabbit">
						<AnimatePresence initial={true}>
							{debounceRabbits.map((uuid, index) => (
								<motion.img
									key={uuid}
									initial={{
										y: '-150%',
										scaleY: 1,
										scaleX: Math.random() > 0.5 ? 1 : -1,
										rotate: Math.random() * 60
									}}
									animate={{
										y: height * 1.3,
										scaleY: 1.5 + Math.random() * 1,
										rotate: Math.random() * 10
									}}
									exit={{
										transition: {
											duration: 0
										}
									}}
									transition={{
										ease: [ 0.31, 0.44, 0.445, 1.65 ],
										delay: Math.random() * 2,
										duration: height / 630 * 1.0
									}}
									onAnimationComplete={(definition) => {
										setDebounceRabbits(remove(debounceRabbits, uuid));
									}}
									src={rabbitImg}
									alt=""
								/>
							))}
						</AnimatePresence>
					</div>
					<div className="scene__hole" />
				</div>
				<h1 className="rabbithole__title" style={{ zIndex: 10 }}>
					Rabbit Hole
				</h1>
				<h3 className="rabbithole__caption arrow" style={{ letterSpacing: 0, zIndex: 10 }}>
					<span>
						<ArrowSVG />
					</span>
				</h3>
			</div>
		</div>
	);
};

export default RabbitHole;
